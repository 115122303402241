<template>
  <Breadcrumb />
  <Grid
    :settings="gridSettings"
    :columns="gridColumns"
    @onEditButtonClick="onEditButtonClick"
  />
</template>
<script>
export default {
  name: "ButtonsDesignList",
  data() {
    return {
      gridSettings: {
        action: "ButtonsDesignList",
        requestUrl: "/Lcdp-ButtonList",
        requestUrlRouteParamNames: [],
        routeRequiredParameters: [],
        isGatewayRequest: true,

        allowSearchPanel: true,
        allowExcelExport: false,
        allowDragAndDrop: false,
        allowPaging: true,
        allowDeleting: false,
        autoOrderProcess: true,

        buttons: [
          {
            name: "edit",
            cssClass: "btn-warning",
            iconClass: "bi-pencil-square",
            routeButton: false,
            emitMethodName: "onEditButtonClick",
          },
        ],
      },
      gridColumns: [
        {
          text: this.$t(
            "Buttons.CustomObjectName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "customObjectName",
          type: "string",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "CustomButtons.Name",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "name",
          type: "string",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "CustomButtons.ButtonType",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "buttonTypeName",
          type: "string",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "CustomButtons.Description",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "description",
          type: "string",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.IsActive",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isActive",
          type: "boolean",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.CreatedBy",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "createdByName",
          type: "string",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.CreatedAt",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "createdAt",
          type: "datetime",
          visible: false,
          width: 0,
          template: "",
          format: this.$store.getters._dateTimeFormat,
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.UpdatedBy",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "updatedByName",
          type: "string",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.UpdatedAt",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "updatedAt",
          type: "datetime",
          visible: false,
          width: 0,
          template: "",
          format: this.$store.getters._dateTimeFormat,
          textAlign: "",
        },
      ],
    };
  },
  methods: {
    onEditButtonClick(rowData) {
      let route = this.$router.resolve({
        name: "CustomObjectEditButton",
        params: {
          customObjectId: rowData.customObjectPublicId,
          buttonId: rowData.publicId,
        },
      });
      window.open(route.href);
    },
  },
  mounted() {},
};
</script>
